import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from "./image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import defaultRTRenderOptions from "../../../contentful/rich-text-renderer-options"
import styles from './aligned-image-block.scss'

class AlignedImageBlock extends React.Component {
  constructor(props) {
    super(props)
    const {sys: { id }, fields:  { title, image, text, extraAttributes, imageAlignment }} = this.props.node    
    const className = get(extraAttributes, 'className', '')
    this.image = image['en-US']
    this.text = text['en-US']
    this.type = get(imageAlignment, 'en-US', 'left');
    this.containerClassName = `image-aligned-block ${className}`
    this.blockId = `image-aligned-block-${this.props.node.sys.id}`
    
    switch (this.type) {
      case 'right':
        this.containerClassName = `${this.containerClassName} image-aligned-block-right`
        break
      case 'left':
        this.containerClassName = `${this.containerClassName} image-aligned-block-left`
        break
    }
    
  }
  render() {    
    return (
      <div className={this.containerClassName} id={this.blockId}>
        <div className="aligned-image-container">
          <Img node={this.image} />
        </div>
        <div className="aligned-text-container">
          {documentToReactComponents(this.text, defaultRTRenderOptions)}
        </div>
        <div className="clearfix"></div>
      </div>
    )
  }
}

export default AlignedImageBlock
